// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';
import Index from './pages/home';
import PageNotFound from './pages/pnf';
import PaidToSiteDetail from './components/PaidToSiteDetail';
import paidToSites from './data';
import reportWebVitals from './reportWebVitals';

ReactGA.initialize('G-T3WRNR298C');

const App: React.FC = () => {
  document.title = "Get Paid To Sites";

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Index />} />
        {paidToSites.map((site) => (
          <Route
            key={site.displayName}
            path={`/sites/${encodeURIComponent(site.displayName.toLowerCase().replace(' ', '-'))}`}
            element={<PaidToSiteDetail site={site} />}
          />
        ))}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
