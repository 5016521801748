// src/data.ts

import { PaidToSite } from './types';
import fiveSurveysLogo from './img/fivesurveys.png'
import primeopinion from './img/primeopinion.png'
const paidToSites: PaidToSite[] = [
	{
		displayName: 'Prolific',
		platforms: ['Web'],
		url: 'https://www.prolific.com',
		loggedInUrl: 'https://app.prolific.com',
		logoUrl: 'https://logo.clearbit.com/prolific.com',
		description: 'Earn money for doing studies. There is a waitlist, the wait time is variable and dependant on your demographics. Screenouts are little to none.',
		payoutMethods: ['PayPal'],
		payoutRestrictions: 'Minimum £6',
		earnMethods: ['Studies', 'Surveys'],
		availability: 'Worldwide',
		referralProgram: false,
		minimumAge: 18,
		termsAndConditionsUrl: 'https://www.mypoints.com/terms',
		privacyPolicyUrl: 'https://www.mypoints.com/privacy',
	},
	{
		displayName: 'UserTesting',
		platforms: ['Web', 'Android', 'iOS'],
		url: 'https://www.usertesting.com',
		loggedInUrl: 'https://app.usertesting.com',
		logoUrl: 'https://logo.clearbit.com/usertesting.com',
		description: 'Get paid to test participate in studies including testing applications. Pay per study is typically $10 or more dollars more than the typical from other sites.',
		payoutMethods: ['PayPal'],
		earnMethods: ['Testing', 'Studies'],
		availability: 'Worldwide',
		referralProgram: false,
		minimumAge: 18,
		termsAndConditionsUrl: 'https://www.usertesting.com/terms',
		privacyPolicyUrl: 'https://www.usertesting.com/privacy',
	},
	{
		displayName: 'Swagbucks',
		platforms: ['Web', 'Android', 'iOS'],
		url: 'https://www.swagbucks.com',
		referalUrl: 'https://www.swagbucks.com/profile/Goodguy140?rp=1',
		logoUrl: 'https://logo.clearbit.com/swagbucks.com',
		description: 'Swagbucks is a rewards site that offers a variety of ways to earn money online, they have been around for a long time and are a well known site.',
		payoutMethods: ['PayPal', 'Prepaid Visa', 'Gift Cards'],
		payoutRestrictions: 'Minimum $25 for PayPal',
		earnMethods: ['Surveys', 'Watching Videos', 'Shopping', 'PTC'],
		availability: 'Worldwide',
		referralProgram: false,
		minimumAge: 13,
		termsAndConditionsUrl: 'https://www.swagbucks.com/terms',
		privacyPolicyUrl: 'https://www.swagbucks.com/privacy',
	},
	{
		displayName: '5 Surveys',
		platforms: ['Web', 'Android', 'iOS'],
		url: 'https://www.fivesurveys.com',
		loggedInUrl: 'https://app.fivesurveys.com',
		logoUrl: fiveSurveysLogo,
		description: 'Earn $5 by completing 5 surveys. Similar to Prime Opinion, but you earn with every 5 surveys instead of individual dollar amounts.',
		payoutMethods: ['PayPal'],
		payoutRestrictions: 'Minimum $5',
		earnMethods: ['Surveys'],
		availability: 'US, CA, UK, AU, +',
		referralProgram: false,
	},
	{
		displayName: 'mTurk',
		platforms: ['Web'],
		url: 'https://www.mturk.com',
		loggedInUrl: 'https://worker.mturk.com',
		logoUrl: 'https://logo.clearbit.com/mturk.com',
		description: 'Earn money by completing micro-tasks. This one is a popular one, but it has declined according to some users, and not to some.',
		payoutMethods: ['Bank Transfer', 'Amazon Gift Card'],
		payoutRestrictions: 'Minimum $1 for Amazon Gift Card',
		earnMethods: ['Tasks'],
		availability: 'Worldwide, but Mostly US',
		referralProgram: false,
	},
	{
		displayName: 'CC Research',
		platforms: ['Web'],
		url: 'https://www.cloudresearch.com',
		loggedInUrl: 'https://connect.cloudresearch.com/participant' ,
		logoUrl: 'https://logo.clearbit.com/cloudresearch.com',
		description: 'Cloud Connect Research. Earn money by completing tasks.',
		payoutMethods: ['PayPal'],
		payoutRestrictions: 'Minimum $5',
		earnMethods: ['Surveys', 'Studies'],
		availability: 'Worldwide',
		referralProgram: false,
	},
	{
		displayName: 'GamerMine',
		platforms: ['Web'],
		url: 'https://gamermine.com/earn',
		urlHidden: true,
		referalUrl: 'https://gamermine.com/r/goodguy',
		logoUrl: 'https://logo.clearbit.com/gamermine.com',
		description: 'Earn coins (1000 = 1 USD) by playing Steam Games, completing offerwalls, watching videos, and more. Coins can be redeemed for PayPal, Bitcoin, Litecoin, and more.',
		payoutMethods: ['PayPal', 'Bitcoin', 'Litecoin'],
		payoutRestrictions: 'Minimum $0.50 for PayPal & Litecoin, $3 for Bitcoin',
		earnMethods: ['Playing Games', 'Offerwalls', 'Watching Videos'],
		availability: 'Worldwide*',
		referralProgram: true,
	},
	{
		displayName: 'Paidviewpoint',
		platforms: ['Web', 'Android'],
		url: 'https://www.paidviewpoint.com',
		urlHidden: true,
		loggedInUrl: 'https://app.paidviewpoint.com/dashboard',
		referalUrl: 'https://paidviewpoint.com/?r=goodguy140',
		logoUrl: 'https://logo.clearbit.com/paidviewpoint.com',
		description: 'Earn money by completing surveys. Surveys come one at a time, and you are paid for each one.',
		payoutMethods: ['PayPal'],
		payoutRestrictions: 'Minimum $15',
		earnMethods: ['Surveys'],
		availability: 'Worldwide',
	},
	{
		displayName: 'User Interviews',
		platforms: ['Web'],
		url: 'https://www.userinterviews.com',
		loggedInUrl: 'https://www.userinterviews.com/studies',
		logoUrl: 'https://logo.clearbit.com/userinterviews.com',
		description: 'Earn money by participating in studies. Each study has a different payout which is displayed before hand.',
		payoutMethods: ['PayPal', 'Giftcards'],
		earnMethods: ['Studies'],
		availability: 'Worldwide',
	},
	{
		displayName: 'Data Annotation',
		platforms: ['Web'],
		url: 'https://www.dataannotation.tech',
		logoUrl: 'https://logo.clearbit.com/dataannotation.tech',
		description: 'Earn money by completing tasks. Requires an inital assessment to be completed before you can start earning.',
		payoutMethods: ['PayPal'],
		earnMethods: ['Tasks'],
		availability: 'Worldwide',
	},
	{
		displayName: 'Remotasks',
		platforms: ['Web', 'Android'],
		url: 'https://www.remotasks.com',
		loggedInUrl: 'https://www.remotasks.com/en/dashboard',
		logoUrl: 'https://logo.clearbit.com/remotasks.com',
		description: 'Earn money by completing tasks.',
		payoutMethods: ['PayPal'],
		earnMethods: ['Tasks'],
		availability: 'Worldwide',
	},
	{
		displayName: 'Prime Opinion',
		platforms: ['Web', 'Android', 'iOS'],
		url: 'https://www.primeopinion.com',
		urlHidden: true,
		referalUrl: 'https://primeopinion.com/register?ref=9bde54de-032c-4aad-b6dc-6eaf0ddeeb38',
		loggedInUrl: 'https://app.primeopinion.com',
		logoUrl: primeopinion,
		description: 'Similar to 5 Surveys, but you earn with dollar amounts per survey instead of earning $5 for every 5 surveys.',
		payoutMethods: ['PayPal'],
		payoutRestrictions: 'Minimum $5',
		earnMethods: ['Surveys'],
		availability: 'Worldwide',

	},
	{
		displayName: 'dScout',
		platforms: ['Web', 'Android', 'iOS'],
		url: 'https://www.dscout.com',
		logoUrl: 'https://logo.clearbit.com/dscout.com',
		description: 'Earn money by participating in studies.',
		payoutMethods: ['PayPal'],
		payoutRestrictions: 'Minimum $1',
		earnMethods: ['Studies'],
		availability: 'Worldwide',
	},
	{
		displayName: 'GAIN',
		platforms: ['Web'],
		url: 'https://gain.gg',
		referalUrl: 'https://gain.gg/r/gg140',
		loggedInUrl: 'https://gain.gg/earn',
		logoUrl: 'https://gain.gg/public/img/favicon.png',
		description: 'Earn money by completing tasks.',
		payoutMethods: ['PayPal', 'Prepaid Visa', 'Giftcards', 'Crypto', 'ACH Bank Transfer (US)'],
		payoutRestrictions: 'Minimum $0.5 for PayPal, $5 for Prepaid Visa, $10 for Crypto',
		earnMethods: ['Surveys', 'Offerwalls', 'Watching Videos', 'Games'],
		availability: 'Worldwide*',
	},
	{
		displayName: 'Clickworker',
		platforms: ['Web', 'Android', 'iOS'],
		url: 'https://www.clickworker.com',
		logoUrl: 'https://logo.clearbit.com/clickworker.com',
		description: 'Earn money by completing tasks.',
		payoutMethods: ['PayPal'],
		payoutRestrictions: 'Minimum $5',
		earnMethods: ['Tasks'],
		availability: 'Worldwide',
	},
	{
		displayName: 'Grindbux',
		platforms: ['Web'],
		url: 'https://www.grindbux.com',
		referalUrl: 'https://grindbux.com/ref/dfffd33f85a5',
		logoUrl: 'https://logo.clearbit.com/grindbux.com',
		description: 'Earn money by completing tasks.',
		payoutMethods: ['Prepaid Visa', 'Giftcards', 'Crypto'],
		payoutRestrictions: 'Minimum $5 Giftcards, $0.25 Crypto',
		earnMethods: ['Surveys', 'Offerwalls'],
		availability: 'Worldwide*',
	},
	{
		displayName: 'Freecash',
		platforms: ['Web', 'Android'],
		url: 'https://www.freecash.com',
		urlHidden: true,
		referalUrl: 'https://freecash.com/r/18c4c10c44',
		loggedInUrl: 'https://www.freecash.com/earn',
		logoUrl: 'https://logo.clearbit.com/freecash.com',
		description: 'Earn money by completing tasks.',
		payoutMethods: ['PayPal', 'Giftcards', 'Crypto'],
		payoutRestrictions: 'Minimum $5 for PayPal, $0.5 for some crypto',
		earnMethods: ['Surveys', 'Offerwalls'],
		availability: 'Worldwide*',
	},
	{
		displayName: 'InboxDollars',
		platforms: ['Web', 'Android', 'iOS'],
		url: 'https://www.inboxdollars.com',
		referalUrl: 'https://www.dailyrewards.com?rb=163421293&ref_src=link',
		logoUrl: 'https://logo.clearbit.com/inboxdollars.com',
		description: 'Earn cash for online activities including watching videos, shopping, and taking surveys.',
		payoutMethods: ['Check', 'Prepaid Visa', 'Gift Cards'],
		earnMethods: ['Surveys', 'Watching Videos', 'Shopping', 'Playing Games'],
		availability: 'United States',
		referralProgram: true,
		minimumAge: 18,
		termsAndConditionsUrl: 'https://www.inboxdollars.com/terms',
		privacyPolicyUrl: 'https://www.inboxdollars.com/privacy',
	},
	{
		displayName: 'Branded Surveys',
		platforms: ['Web', 'Android', 'iOS'],
		url: 'https://surveys.gobranded.com/',
		referalUrl: 'https://surveys.gobranded.com/users/register/CFRA5902',
		loggedInUrl: 'https://surveys.gobranded.com/members',
		logoUrl: 'https://logo.clearbit.com/gobranded.com',
		description: 'Earn money by completing surveys and Daily Polls.',
		payoutMethods: ['PayPal', 'PrePaid Visa/MC', 'Giftcards'],
		payoutRestrictions: 'Minimum $5',
		earnMethods: ['Surveys', 'Daily Polls'],
		availability: 'Worldwide',
		referralProgram: true
	},
	{
		displayName: 'GG2U',
		platforms: ['Web'],
		url: 'https://www.gg2u.org',
		logoUrl: 'https://logo.clearbit.com/gg2u.org',
		description: 'Earn money by completing tasks.',
		payoutMethods: ['PayPal', 'Giftcards', 'Crypto'],
		payoutRestrictions: 'Minimum $7',
		earnMethods: ['Surveys', 'Offerwalls', 'Watching Videos', 'Games'],
		availability: 'Worldwide*',
	},
	{
		displayName: 'PrizeRebel',
		platforms: ['Web'],
		url: 'https://www.prizerebel.com',
		referalUrl: 'https://www.prizerebel.com/index.php?r=15112437',
		logoUrl: 'https://logo.clearbit.com/prizerebel.com',
		description: 'Earn money by completing tasks.',
		payoutMethods: ['PayPal', 'Prepaid Visa'],
		payoutRestrictions: 'Minimum $2',
		earnMethods: ['Surveys', 'Offerwalls'],
		availability: 'Worldwide*',
	},
	{
		displayName: 'Attapoll',
		platforms: ['Android', 'iOS'],
		url: 'https://attapoll.app',
		referalUrl: 'https://attapoll.app/join/yzqdd',
		logoUrl: 'https://logo.clearbit.com/attapoll.app',
		description: 'Earn money by completing surveys.',
		payoutMethods: ['PayPal', 'Revolut', 'Giftcards'],
		payoutRestrictions: 'Minimum $5 Paypal, $4.50 Revolut and Amazon GC',
		earnMethods: ['Surveys'],
	}
];

export default paidToSites;
