// src/components/PaidToSitesList.tsx

import React, { useState } from 'react';
import { PaidToSite } from '../types';
import './PaidToSitesList.css'; // Import CSS styles
import { Link } from 'react-router-dom';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    sites: PaidToSite[];
}

const PaidToSitesList: React.FC<Props> = ({ sites }) => {
    let [filter, setFilter] = useState(['Web', 'Android', 'iOS']);
    const [sort, setSort] = useState('default');

    function changeFilter(cFilter: string) {
        if (filter.includes(cFilter)) {
            setFilter(filter.filter(f => f !== cFilter));
        } else {
            setFilter([...filter, cFilter]);
        }
    }

    function handleSortChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setSort(event.target.value);
    }

    const sortedSites = sites.sort((a, b) => {
        switch (sort) {
            case 'a-z':
                return a.displayName.localeCompare(b.displayName);
            case 'z-a':
                return b.displayName.localeCompare(a.displayName);
            default:
                return 0; // No sorting
        }
    });

    return (
        <div>
            <h4 className='filter-info'>
                {
                    filter.length === 3
                        ? 'Showing all sites'
                        : `Showing sites for ${filter.join(', ')}`
                }
                {
                    ` (${sites.filter(site => site.platforms?.some(platform => filter.includes(platform))).length} sites)`
                }
            </h4>
            <div className='container'>
                <div className='filter-bar'>
                    <div className='filter-buttons'>
                        <button
                            onClick={() => { changeFilter('Web'); }}
                            className={`filter-button ${!filter.includes('Web') ? 'grey-button' : ''}`}
                        >
                            Web
                        </button>
                        <button
                            onClick={() => { changeFilter('Android'); }}
                            className={`filter-button ${!filter.includes('Android') ? 'grey-button' : ''}`}
                        >
                            Android
                        </button>
                        <button
                            onClick={() => { changeFilter('iOS'); }}
                            className={`filter-button ${!filter.includes('iOS') ? 'grey-button' : ''}`}
                        >
                            iOS
                        </button>
                        <select className='sort-dropdown' value={sort} onChange={handleSortChange}>
                            <option value='default'>Sort by...</option>
                            <option value='a-z'>A-Z</option>
                            <option value='z-a'>Z-A</option>
                            {/* Add more options as needed... */}
                        </select>
                    </div>
                </div>
                <div className="site-list">
                    {sites.filter(site => site.platforms?.some(platform => filter.includes(platform))).map((site, index) => (
                        <div key={index} className="site-item">
                            <Link to={`/sites/${encodeURIComponent(site.displayName.toLowerCase().replace(' ', '-'))}`}>
                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
                            </Link>
                            <div className="site-header">
                                {site.logoUrl && (
                                    <img
                                        src={site.logoUrl}
                                        alt={site.displayName}
                                        className="site-logo"
                                    />
                                )}
                                <div className='site-logo-text'>
                                    <h3 className="site-title">
                                        {site.displayName}
                                    </h3>
                                    <h4 className="site-subtitle">
                                        {site.platforms?.join(', ') || 'Platform not specified'}
                                    </h4>
                                </div>
                            </div>
                            <div className='site-buttons'>
                                {!site.urlHidden && (
                                    <a href={site.url} target="_blank" rel="noopener noreferrer">
                                        <button className="button">Visit Site</button>
                                    </a>
                                )}
                                {site.urlHidden && site.referalUrl && (
                                    <a href={site.referalUrl} target="_blank" rel="noopener noreferrer">
                                        <button className="button">Visit Site</button>
                                    </a>
                                )}
                                {!site.urlHidden && site.referalUrl && (
                                    <a href={site.referalUrl} target="_blank" rel="noopener noreferrer">
                                        <button className="button referal">Referal</button>
                                    </a>
                                )}
                                {site.loggedInUrl && (
                                    <a href={site.loggedInUrl} target="_blank" rel="noopener noreferrer">
                                        <button className="button">Loggedin?</button>
                                    </a>
                                )}
                            </div>
                            <div className="site-info">
                                <p>
                                    {site.description}
                                </p>
                                <p>
                                    <strong>Payout Methods:</strong> {site.payoutMethods.join(', ')}
                                </p>
                                {site.payoutRestrictions && (
                                    <p>
                                        <strong>Payout Restrictions:</strong> {site.payoutRestrictions}
                                    </p>
                                )}
                                <p>
                                    <strong>Earn Methods:</strong> {site.earnMethods.join(', ')}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PaidToSitesList;
