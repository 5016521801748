const PageNotFound: React.FC = () => {
	return (
	<div>
		<main>
			<div className="container">
				<h1>Page Not Found</h1>
				<a href="/">Home</a>
			</div>
		</main>
	</div>
	);
};
export default PageNotFound;