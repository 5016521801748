// src/components/Header.tsx

import React from 'react';
import './Header.css'; // Import CSS styles

interface Props {
	title: string;
}



const Header: React.FC<Props> = ({ title }) => {
	return (
		<header>
			<h1>{title}</h1>
		</header>
	);
};

export default Header;
