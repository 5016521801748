import Footer from "../components/Footer";
import Header from "../components/Header";
import PaidToSitesList from "../components/PaidToSitesList";
import paidToSites from "../data";

const Home: React.FC = () => {
	return (
		document.title = "Get Paid To Sites",
		<div className="App">
			<Header title="Get Paid To Sites" />
			<main>
				<div className="container">
					<PaidToSitesList sites={paidToSites} />
				</div>
			</main>
			<Footer />
		</div>
	);
};
export default Home;