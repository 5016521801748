// src/components/PaidToSiteDetail.tsx

import React from 'react';
import { PaidToSite } from '../types';
import './PaidToSiteDetail.css';
interface Props {
    site: PaidToSite;
}

const PaidToSiteDetail: React.FC<Props> = ({ site }) => {

    if (!site) {
        return <div>Site not found.</div>;
    }

    return (
        <div className="site-detail-container">
            <div className="site-detail-header">
                {site.logoUrl && (
                    <img
                        src={site.logoUrl}
                        alt={site.displayName}
                        className="site-detail-logo"
                    />
                )}
                <div>
                    <h1 className="site-detail-title">{site.displayName}</h1>
                    <h3 className="site-detail-subtitle">{site.platforms?.join(', ') || 'Platform not specified'}</h3>
                </div>
            </div>
            <p className="site-detail-description">{site.description}</p>
            <div className="site-detail-info">
                <p><strong>Payout Methods:</strong> {site.payoutMethods.join(', ')}</p>
                {site.payoutRestrictions && (
                    <p><strong>Payout Restrictions:</strong> {site.payoutRestrictions}</p>
                )}
                <p><strong>Earn Methods:</strong> {site.earnMethods.join(', ')}</p>
                {site.availability && <p><strong>Availability:</strong> {site.availability}</p>}
            </div>
            <div className="site-detail-buttons">
                <a href={site.url} target="_blank" rel="noopener noreferrer">
                    <button className="button visit">Visit Site</button>
                </a>
                {site.referralProgram && (
                    <a href={site.referalUrl} target="_blank" rel="noopener noreferrer">
                        <button className="button referral">Referral</button>
                    </a>
                )}
                {site.loggedInUrl && (
                    <a href={site.loggedInUrl} target="_blank" rel="noopener noreferrer">
                        <button className="button logged-in">Logged In?</button>
                    </a>
                )}
            </div>
        </div>
    );
};

export default PaidToSiteDetail;