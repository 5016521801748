// src/components/Footer.js

import React from 'react'; // Import React
import './Footer.css'; // Import CSS for footer styles
const Footer = () => {
	return (
		<body>
			<footer className="footer">
				<div className="footer-content">
					<div className="footer-links">
						<a href="mailto:inquires@goodguy140.com">Contact</a>
						{/* <a href="/privacy">Privacy Policy</a> */}
					</div>
					<div className="footer-info">
						<p>Beer Money Sites</p>
						<p>Created by goodguy140</p>
					</div>
				</div>
			</footer>
		</body>
	);
};

export default Footer;
